<template>
  <div>
    <v-row justify="space-around">
      <v-card flat width="100%" >
        <!-- <v-img width="100%" :height="$vuetify.breakpoint.xlOnly ? 1000 : $vuetify.breakpoint.lgOnly  ? 800 : ''"
          src="../../../src/assets/images/feature/meet.png"
          class="d-flex align-center img-gradient transparent-box img-gradient-overlay-industries"
         :class="$vuetify.breakpoint.smAndUp ? 'picture': ''"> -->
         <template v-for="(item, index) in  featuresObj.features" >
          <v-img width="100%" aspect-ratio="2" :height="$vuetify.breakpoint.xlOnly ? 650 : $vuetify.breakpoint.lgOnly  ? 600 : ''"
            :src="item.feature_background_image"
            class="d-flex align-center img-gradient transparent-box img-gradient-overlay-industries"
            :class="$vuetify.breakpoint.smAndUp ? '': ''" :key="`img-${index}`" >
            <cube-animation></cube-animation>
            <v-container class="apply-index"  :class="$vuetify.breakpoint.lgAndUp ? [ 'apply-width-feature']: ''">
                <v-row justify="center"  :class="$vuetify.breakpoint.xsOnly ? 'apply-column' : ''" >
                  <v-col xs="12" lg="8" xl="7" md="6" sm="8">
                    <v-container class="pa-15">
                      <h1 class="white--text font-weight-bold text-left" :class="$vuetify.breakpoint.smAndUp  ? 'text-h1':' text-h6'">{{ item.title }}</h1>
                      <p class=" white--text text-subtitle-1 text-left apply-text mt-1 text-subtitle-1 mt-4"> {{ item.feature_description }}</p>
                        <v-btn :width="$vuetify.breakpoint.lgAndUp ? 190 : 150" @click="$router.push(`${item.book_demo}`)"
                          class="rounded-pill white--text ml-1 pa-6 font-weight-bold
                          apply-gradient-demo no-text-transform"
                          :samll=" $vuetify.breakpoint.mdAndDown"
                          :x-large="$vuetify.breakpoint.lgAndUp"> Book a demo
                        </v-btn>
                    </v-container>
                  </v-col>
                  <v-col xs="12" lg="4" xl="5" md="6" sm="8">
                    <v-img width="100%" aspect-ratio="1" contain :src="item.feature_image" class="d-flex align-center"></v-img>
                  </v-col>
                </v-row>
            </v-container>
          </v-img>
         </template>
      </v-card>
    </v-row>
    <v-row class="pa-14">
      <v-container :class="$vuetify.breakpoint.lgAndUp ? [ 'apply-width-feature']: ''">
        <v-row :no-gutters="$vuetify.breakpoint.smAndUp" :class="$vuetify.breakpoint.xsOnly ? 'apply-column' : ''">
          <v-col
            xs="12" lg="4" xl="4" md="6" sm="6" v-for="(item, index) in
            (
              featuresObj && featuresObj.features_content ? featuresObj.features_content.slice(0, 3) : [])"
            :key="index"
          >
          <v-card tile outlined  height="100%" width="100%" class="pa-5 corner-frame">
          <v-card-title class=" d-flex justify-left">
            <v-avatar class="apply-gradient-demo">
              <v-icon size="24" color="#ffffff" >{{ item.icon }}</v-icon>
            </v-avatar>
            </v-card-title>
            <v-card-text class="pa-0 d-flex justify-left ml-3">
                <span class="font-weight-bold text-subtitle-1" style="color: #303F9F !important">{{ item.title }}</span>
            </v-card-text>
            <v-card-text class="mt-1  text-subtitle-1">
              <v-row>
                <v-col cols="1" class="pa-0">
                  <v-icon class="custom-icon pa-0" size="26">mdi-check-circle-outline</v-icon>
                </v-col>
                <v-col cols="11" class="pa-0">
                  <p class="ml-lg-3" :class="$vuetify.breakpoint.mdAndDown ? 'ml-3' : ''">{{ item.feature_content_description }}</p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          </v-col>
           <v-col v-if="$vuetify.breakpoint.lgAndUp"
            xs="12" lg="6" xl="3" md="6" sm="6"
          >
          <template>
            <v-card tile outlined  height="100%" width="100%">
            <v-img height="100%" width="100%"
             src="../../assets/images/feature-logo.png"></v-img>
            </v-card>
          </template>
           </v-col>
          <v-col
            xs="12" :lg="6" xl="3" md="6" sm="6" v-for="(item, index) in  (
              featuresObj && featuresObj.features_content ? featuresObj.features_content.slice(3, 6) : [])"
            :key="`subcards-${index}`"
          >
           <v-card tile outlined  height="100%" width="100%" class="pa-5 corner-frame">
              <v-card-title class="d-flex justify-left">
                <v-avatar  class="apply-gradient-demo">
                <v-icon size="24" color="#ffffff" >{{ item.icon }}</v-icon>
              </v-avatar>
            </v-card-title>
              <v-card-text class="pa-0 d-flex justify-left ml-3">
                <span class="font-weight-bold text-subtitle-1" style="color: #303F9F !important">{{ item.title }}</span>
              </v-card-text>
            <v-card-text class="mt-1 text-subtitle-1">
              <v-row>
                <v-col cols="1" class="pa-0">
                  <v-icon class="custom-icon pa-0" size="26">mdi-check-circle-outline</v-icon>
                </v-col>
                <v-col cols="11" class="pa-0">
                  <p class="ml-1 ml-xl-2" :class="$vuetify.breakpoint.mdAndDown ? 'ml-3' : ''">{{ item.feature_content_description }}</p>
                </v-col>
              </v-row>
            </v-card-text>
           </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </div>
</template>
<script>
import { api } from '../../plugins/axios'
export default {
  data () {
    return {
      featuresObj: {},
      colors: ['#42A5F5', '#7E57C2', '#EF5350', '#FFB74D', '#00796B', '#8BC34A'],
      icons: ['mdi-blur', 'mdi-file-document', 'mdi-note-multiple-outline', 'mdi-map-marker-outline', 'mdi-chart-donut', 'mdi-screen-rotation']
    }
  },
  mounted () {
    this.getFeatures()
    this.addIcons(this.featuresObj)
    this.scrollToTop()
  },
  components: {
    'cube-animation': () => import('../SquareAnimation/CubesAnimation')
  },
  methods: {
    getFeatures () {
      api.get('/features')
        .then((response) => {
          if (response.data) {
            this.featuresObj = response.data
            if (this.featuresObj) this.addIcons(this.featuresObj)
          }
        })
        .catch((error) => {
          console.log(error.message)
          if (error.response && error.response.status === 500) {
            this.$router.push('/technical-difficulties')
          }
        })
    },
    addIcons (getObj) {
      if (getObj && getObj.features_content) {
        getObj.features_content.forEach((item, index) => {
          item.icon = this.icons[index]
        })
      }
    },
    scrollToTop () {
      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Adds smooth scrolling behavior
      })
    }
  }
}
</script>

<style  lang='scss'>
@import '../../assets/css/_squares.scss';
.apply-background {
   background: #EDF5FB !important;
}
.circular-div {
  width: 60px; /* Set the width and height to make a square */
  height: 60px;
  border-radius: 50%; /* Set border-radius to 50% for a circular shape */
}
.apply-gradient {
      background: linear-gradient(to right, #c8bbdf, #FF7C82);
      color: #000000;
}
.transparent-box {
  opacity: 0.9; /* Set the opacity to 70% */
}
.apply-background-cards {
  background: #35313A !important;
}
.apply-text {
    text-align: justify !important;
    // line-height: 2em !important;
    // font-size: 2rem !important;
}
.picture {
        // background-size: cover;
        // height: 23rem;
        background-blend-mode: screen;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
        // border-top-left-radius: 3px;
        // border-top-right-radius: 3px;
}
.apply-width-feature {
  width: 60% !important;
}
.apply-column {
  display: flex !important;
  flex-direction: column !important;
}
.apply-gradient-demo {
      background: linear-gradient(to right, #7E57C2, #FF7C82);
      color: #000000;
}
.custom-icon {
  background: linear-gradient(to right, #7E57C2, #FF7C82);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.justify-text-apply {
  text-align: justify !important;
   /* line-height: 1.8 !important; */
}
.corner-frame {
        position: relative;
        width: 300px;
        height: 200px;
        background-color: #f0f0f0;
        padding: 20px;
    }
.corner-frame::before,
.corner-frame::after {
 content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  }
  .corner-frame::before {
    top: 0;
    left: 0;
    background: linear-gradient(to top left, transparent 50%, #912888 50%);
  }
  .corner-frame::after {
    bottom: 0;
    right: 0;
    background: linear-gradient(to top left, transparent 50%, #3953A3 50%);
    }
</style>
